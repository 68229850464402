<template>
  <div class="row">
    <div class="col-12">
      <input-text
        ref="descricao"
        v-model="form.descricao"
        type="text"
        :label="$t('ACADEMICO.NOME')"
        :placeholder="$t('ACADEMICO.NOME')"
        :maxLength="50"
        :showLengthLimit="false"
        :minLength="6"
        required
      />
    </div>
    <div class="col-12 col-md-6">
      <input-text
        ref="cargaHoraria"
        v-model="form.cargaHoraria"
        type="number"
        :label="$t('ACADEMICO.CARGA_HORARIA')"
        :placeholder="$t('ACADEMICO.CARGA_HORARIA')"
        :maxLength="6"
        :showLengthLimit="false"
        required
      />
    </div>
    <div class="col-12 col-md-6">
      <input-select-search
        ref="tipoContrato"
        :label="$t(`ACADEMICO.TIPO_CONTRATO`)"
        :options="opcoes.tipoContrato"
        v-model="form.tipoContrato"
        required
      />
    </div>
        <div class="col-12 col-md-6">
      <input-select-search
        ref="fase"
        :label="$t(`Fase`)"
        :options="opcoes.fase"
        v-model="form.fase"
        required
      />
    </div>
    <div class="col-12 col-md-6">
      <input-text
        ref="planoEnsino"
        v-model="form.planoEnsino"
        type="text"
        :label="$t('ACADEMICO.PLANO_ENSINO')"
        :placeholder="$t('ACADEMICO.PLANO_ENSINO')"
        :minLength="6"
        required
      />
    </div>
    <div class="col-12 h-25">
      <input-text-area
        ref="ementa"
        v-model="form.ementa"
        type="text"
        :label="$t('ACADEMICO.EMENTA')"
        :placeholder="$t('ACADEMICO.EMENTA')"
        :minLength="6"
        required
      />
    </div>
  </div>
</template>
<script>
import {
  InputText,
  InputTextArea,
  InputSelectSearch,
} from '@/components/inputs';

export default {
  props: {
    form: { type: Object, default: Object },
    opcoes: { type: Object, default: Object },
  },
  components: {
    InputText,
    InputTextArea,
    InputSelectSearch,
  },
  mounted() {
    this.$emit('refs', this.$refs);
  },
};
</script>
