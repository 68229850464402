<template>
  <b-table
    :fields="fields"
    :items="cursos"
    :empty-text="$t('GERAL.NENHUM_REGISTRO')"
    head-variant="light"
    show-empty
    striped
    hover
  >
    <template #cell(acoes)="item">
      <b-dropdown variant="outline-default">
        <b-dropdown-item @click="editar(item.item)">
          {{ $t('GERAL.EDITAR') }}
        </b-dropdown-item>
        <b-dropdown-item @click="excluir(item.item)">
          {{ $t('GERAL.EXCLUIR') }}
        </b-dropdown-item>
        <b-dropdown-item @click="irPara('academico-curso-x', item.item.id)">
          {{ $t('ACADEMICO.VISUALIZAR_AULAS_CURSO') }}
        </b-dropdown-item>
      </b-dropdown>
    </template>
  </b-table>
</template>
<script>
// Utils & Aux:
import rotas from '@/common/utils/rotas';
import TurmasService from '@/common/services/academico/turma.service.js';
import mensagem from '@/common/utils/mensagem';

export default {
  props: {
    cursos: { type: Array, default: Array },
  },
  data() {
    return {
      fields: [
        {
          key: 'descricao',
          label: this.$t('GERAL.NOME'),
          sortable: true,
        },
        {
          key: 'cargaHoraria',
          label: this.$t('GERAL.CARGA_HORARIA'),
          sortable: true,
        },
        {
          key: 'tipoContratoDescricao',
          label: this.$t('GERAL.TIPO_CONTRATO'),
          sortable: true,
        },
        {
          key: 'acoes',
          label: this.$t('GERAL.ACOES'),
        },
      ],
    };
  },
  methods: {
    // FUNÇÕES CRUD:
    editar(item) {
      this.$emit('editar', 'curso', item);
    },
    excluir(item) {
      TurmasService.buscarPorCursoId(item.id).then(({ data }) => {
        if (data.length === 0) {
          this.$emit('excluir', item);
        } else {
          mensagem.erro(
            this.$t('GERAL.ERRO'),
            this.$t('Não é possível excluir um curso que possua turmas')
          );
          return;
        }
      });
    },
    // FUNÇÕES AUXILIARES:
    irPara(rota, id) {
      rotas.irPara(rota, id);
    },
  },
};
</script>
