<template>
  <topbar
    :titulo="$t('ACADEMICO.CURSOS')"
    :subtitulo="$t('ACADEMICO.CURSOS_CEJ_DESCRICAO')"
    :breadcrumbs="breadcrumbs"
  >
    <template #header>
      <div class="d-flex justify-content-between ml-4">
        <div></div>
        <b-button @click="cadastrar" variant="info" class="mr-2">
          {{ $t('ACADEMICO.CURSOS_CADASTRAR') }}
        </b-button>
      </div>
    </template>
  </topbar>
</template>
<script>
// Utils & Aux:
import rotas from '@/common/utils/rotas';
import Topbar from '@/layouts/full-layout/horizontal-sidebar/Topbar.vue';

export default {
  components: { Topbar },
  data() {
    return {
      breadcrumbs: [
        { titulo: this.$t('ACADEMICO_HOME.PAINEL_ACADEMICO') },
        { titulo: this.$t('ACADEMICO.ESTRUTURA_CEJ') },
        { titulo: this.$t('ACADEMICO.CURSOS') },
      ],
    };
  },
  methods: {
    // FUNÇÕES CRUD:
    cadastrar() {
      this.$emit('cadastrar', 'curso');
    },
    // FUNÇÕES AUXILIARES:
    irPara(rota, id) {
      rotas.irPara(rota, id);
    },
  },
};
</script>
