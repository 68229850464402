<template>
  <modal
    :exibir="exibir"
    @fechar="fecharModal"
    :titulo="'Curso'"
    :campos="$refs"
    id="modalCurso"
  >
    <form-curso :form="form" :opcoes="opcoes" @refs="capturarRefs" />
    <template #modal-footer>
      <div class="w-100 d-flex justify-content-between">
        <b-button variant="secondary" @click="fecharModal">
          {{ $t('GERAL.FECHAR') }}
        </b-button>
        <b-button variant="primary" @click="salvar">
          {{ $t('GERAL.SALVAR') }}
        </b-button>
      </div>
    </template>
  </modal>
</template>
<script>
// Utils & Aux:
import { START_LOADING, STOP_LOADING } from '@/store/Store';
import mensagem from '@/common/utils/mensagem';
import helpers from '@/common/utils/helpers';

// Services:
import EnumeradoresService from '@/common/services/enumeradores/enumeradores.service.js';
import CursoService from '@/common/services/academico/curso.service.js';

// Components:
import Modal from '@/components/modal/Modal';
import FormCurso from './Form';

export default {
  props: {
    exibir: { type: Boolean, default: false },
    form: { type: Object, default: Object },
  },
  components: {
    Modal,
    FormCurso,
  },
  data() {
    return {
      refs: [],
      opcoes: {
        tipoContrato: [],
        fase: [],
      },
    };
  },
  mounted() {
    this.getTipoContrato();
    this.getFase();
  },
  methods: {
    // FUNÇÕES OBTENÇÃO DE DADOS:
    getTipoContrato() {
      this.$store.dispatch(START_LOADING);
      EnumeradoresService.listar('tipo-contratos')
        .then(({ data }) => {
          data = data.map((row) => ({
            ...row,
            value: row.id,
            text: row.descricao,
          }));
          this.opcoes.tipoContrato = data;
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    getFase() {
      this.$store.dispatch(START_LOADING);
      EnumeradoresService.listar('fase')
        .then(({ data }) => {
          data = data.map((row) => ({
            ...row,
            value: row.id,
            text: row.descricao,
          }));

          this.opcoes.fase = data;
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    // FUNÇÕES MODAL:
    fecharModal() {
      this.$emit('fechar');
    },
    // FUNÇÕES CRUD:
    salvar() {
      if (!this.validarFormulario()) return;
      this.form.id ? this.editar() : this.criar();
    },
    criar() {
      this.$store.dispatch(START_LOADING);
      CursoService.criar(this.form)
        .then(() => {
          mensagem.sucesso(this.$t('GERAL.SUCESSO'));
          this.$emit('atualizar-tabela');
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.fecharModal();
          this.$store.dispatch(STOP_LOADING);
        });
    },
    editar() {
      this.$store.dispatch(START_LOADING);
      CursoService.editar(this.form)
        .then(() => {
          mensagem.sucesso(this.$t('GERAL.SUCESSO'));
          this.$emit('atualizar-tabela');
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.fecharModal();
          this.$store.dispatch(STOP_LOADING);
        });
    },
    // FUNÇÕES AUXILIARES:
    validarFormulario() {
      return helpers.validarFormulario(this.refs);
    },
    capturarRefs(refs) {
      this.refs = refs;
    },
  },
};
</script>
