export default class Curso {
    constructor({
        cargaHoraria,
        descricao,
        ementa,
        grupoTurmas,
        planoEnsino,
        precos,
        tipoContrato
    }) {
        this.cargaHoraria = cargaHoraria
        this.descricao = descricao
        this.ementa = ementa
        this.grupoTurmas = grupoTurmas
        this.planoEnsino = planoEnsino
        this.precos = precos
        this.tipoContrato = tipoContrato
    }
}