<template>
  <div>
    <titulo-cursos @cadastrar="openModal" />

    <tabela-cursos
      :cursos="cursos"
      @editar="openModal"
      @excluir="confirmarExclusao"
    />

    <modal-curso
      :form="modais.cursoDados"
      :exibir="modais.curso"
      @atualizar-tabela="getCursos"
      @fechar="closeModal"
    />
  </div>
</template>
<script>
// Utils & Aux:
import { START_LOADING, STOP_LOADING } from '@/store/Store';
import mensagem from '@/common/utils/mensagem';

// Service & Models:
import CursoService from '@/common/services/academico/curso.service';
import Curso from '@/common/models/academico/Curso';

// Components:
import TituloCursos from '@/views/academico/cursos/components/Titulo.vue';
import TabelaCursos from '@/views/academico/cursos/components/Tabela.vue';
import ModalCurso from '@/views/academico/cursos/components/Modal.vue';

export default {
  mounted() {
    this.getCursos();
  },
  components: {
    TituloCursos,
    TabelaCursos,
    ModalCurso,
  },
  data() {
    return {
      cursos: [],
      modais: {
        curso: false,
        cursoDados: new Curso({}),
      },
    };
  },
  methods: {
    getCursos() {
      this.$store.dispatch(START_LOADING);
      CursoService.listar()
        .then(({ data }) => {
          this.cursos = data;
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    // FUNÇÕES CRUD:
    async confirmarExclusao(item) {
      const respostaExclusao = await mensagem.confirmacao(
        this.$t('ACADEMICO.CURSO_CONFIRMAR_EXCLUSAO_TITULO').formatUnicorn({
          curso: item.descricao,
        }),
        this.$t('ACADEMICO.AVISO_NAO_PODE_DESFAZER_ACAO')
      );
      if (respostaExclusao.isConfirmed) {
        this.excluir(item.id);
      }
    },
    excluir(itemId) {
      this.$store.dispatch(START_LOADING);
      CursoService.excluir(itemId)
        .then(({ data }) => {
          mensagem.sucesso(this.$t('GERAL.SUCESSO'), data);
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.getCursos();
          this.$store.dispatch(STOP_LOADING);
        });
    },
    // FUNÇÕES MODAL:
    openModal(key, item) {
      item
        ? (this.modais[`${key}Dados`] = JSON.parse(JSON.stringify(item)))
        : (this.modais[`${key}Dados`] = new Curso({}));
      this.modais[key] = true;
    },
    closeModal() {
      this.modais.curso = false;
    },
  },
};
</script>
